import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import { AgGridReact } from "ag-grid-react";
import { AgTableClient } from "app/shared/AgTable";
import { Button } from "antd";
import FinkAI from "app/shared/FinkAI";
import SpeechToText from "app/shared/FinkAI/SpeechToText";
// import jsonData from "../Onboarding/components/OnboardAirlineReconView/data.json";

export default function FinkTest(props: any) {
  const [activeKey, setActiveKey] = useState(1);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const gridRef = useRef<any>();
  const PriceRenderer = (props: any) => {
    return <span>${props.value.toLocaleString()}</span>;
  };

  const MileageRenderer = (props: any) => {
    return <span>{props.value.toLocaleString()} miles</span>;
  };

  const columnDefs = [
    {
      field: "Vendor Name",
      headerName: "Vendor Name",
      minWidth: 200,
      sortable: true,
      filter: true,
      editable: true,
      enableRowGroup: true,
      expanded: false,
      cellRendererFramework: (params: any) => (
        <button>{params.data.expanded ? "-" : "+"}</button>
      ),
    },
    {
      field: "Claimable Status",
      headerName: "Claimable Status",
      minWidth: 200,
      sortable: true,
      filter: "agTextColumnFilter",
      editable: true,
      enableRowGroup: true,
    },

    {
      field: "Note Type",
      headerName: "Note Type",
      minWidth: 200,
      sortable: true,
      filter: true,
      editable: true,
      enableRowGroup: true,
    },
    {
      field: "Type",
      headerName: "Type",
      minWidth: 200,
      sortable: true,
      filter: true,
      editable: true,
      enableRowGroup: true,
    },
    {
      field: "Invoice-Total_GST",
      headerName: "Total GST Amount",
      minWidth: 200,
      sortable: true,
      editable: true,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "Invoice-Total_Amount",
      headerName: "Total Invoice Amount",
      minWidth: 200,
      sortable: true,
      editable: true,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
  ];

  const rowData = [
    {
      make: "Toyota",
      model: "Celica",
      year: 2020,
      color: "Red",
      price: 35000,
      mileage: 10500,
      details: [{ price: 12000 }, { price: 10000 }, { price: 10000 }],
    },
    {
      make: "Ford",
      model: "Mondeo",
      year: 2019,
      color: "Blue",
      price: 32000,
      mileage: 25000,
      details: [{ price: 12000 }, { price: 10000 }, { price: 13000 }],
    },
    {
      make: "Porsche",
      model: "Boxster",
      year: 2018,
      color: "Black",
      price: 72000,
      mileage: 15000,
      details: [{ price: 12000 }, { price: 3000 }],
    },
    {
      make: "BMW",
      model: "3 Series",
      year: 2020,
      color: "White",
      price: 41500,
      mileage: 9800,
      details: [{ price: 41500 }],
    },
    {
      make: "Audi",
      model: "A4",
      year: 2019,
      color: "Gray",
      price: 38000,
      mileage: 22000,
      details: [{ price: 38000 }],
    },
    {
      make: "Mercedes",
      model: "C Class",
      year: 2021,
      color: "Silver",
      price: 50000,
      mileage: 3300,
      details: [{ price: 50000 }],
    },
    {
      make: "Volkswagen",
      model: "Golf",
      year: 2017,
      color: "Green",
      price: 27000,
      mileage: 40000,
      details: [{ price: 50000 }],
    },
    {
      make: "Honda",
      model: "Civic",
      year: 2018,
      color: "Yellow",
      price: 29000,
      mileage: 37000,
      details: [{ price: 50000 }],
    },
    {
      make: "Hyundai",
      model: "Elantra",
      year: 2019,
      color: "Purple",
      price: 23000,
      mileage: 29000,
      details: [{ price: 50000 }],
    },

    {
      make: "Nissan",
      model: "Altima",
      year: 2020,
      color: "Orange",
      price: 31000,
      mileage: 18000,
      details: [{ price: 50000 }],
    },
  ];

  const defaultActions = [
    {
      name: "Show Unclaimed Transactions",
      model: {
        groupModel: [],
        filterModel: {
          "Claimable Status": {
            type: "equals",
            filter: "Unclaimable",
          },
        },
        sortModel: [],
      },
    },
    {
      name: "Show Claimed Transactions",
      model: {
        groupModel: [],
        filterModel: {
          "Claimable Status": {
            type: "equals",
            filter: "Claimable",
          },
        },
        sortModel: [],
      },
    },
  ];

  const applySortingAndGrouping = (models: any) => {
    let jsonmodel = {
      groupModel: [
        {
          colId: "year",
          hide: true, // Optionally hide the group column in the main grid
        },
      ],
      filterModel: {},
      sortModel: [],
    };

    gridRef.current.setGroupModel(models.groupModel);

    gridRef.current.setFilterModel(models.filterModel);
    gridRef.current.applyColumnState(models.sortModel);
    // gridRef.current.setSortModel(models.sortModel);

    console.log("models are", models);

    // If you need to expand groups programmatically after setting filters
    // gridRef.current.forEachNode((node: any) => {
    //   node.setExpanded(true);
    // });
  };

  return (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <div style={{ height: 600 }}>
          <AgTableClient
            // @ts-ignore
            rowData={rowData}
            ref={gridRef}
            groupPanel
            columnDefs={columnDefs}
          />
        </div>
      </div>
      {/* <FinkAI
        onResponse={applySortingAndGrouping}
        defaultActions={defaultActions}
      /> */}
    </div>
  );
}
