import { Button, Divider, Statistic, StatisticProps, message } from "antd";
import { API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE } from "app/scenes/Credentials/credentials.constants";
import { apiPost } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import Uploader from "app/shared/Uploader";
import { colorPicker } from "app/utils/color.helper";
import csv from "csvtojson";

import { useState } from "react";
import CountUp from "react-countup";

export default function BulkCreateGSTCreds(props: any) {
  const [bulkdata, setBulkData] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleCreateCredential = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE, {
      credentials: bulkdata,
    });
    if (response.status) {
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const furtherProcessJSON = (jsonData: any) => {
    const keysToCheck = ["gstin", "username", "password"];

    const allObjectsContainKeysAndValues = jsonData.every((obj: any) =>
      keysToCheck.every((key) => obj.hasOwnProperty(key) && obj[key])
    );
    if (allObjectsContainKeysAndValues) {
      //   handleBulkUpload(jsonData);
      setBulkData(jsonData);
      setFileUploaded(true);
    } else {
      messageApi.error({
        type: "error",
        content:
          "Invalid csv, mybe some filed does not have value( ie: username)",
      });
    }
  };

  const handleReadFileData = async (file: any) => {
    if (!file.originFileObj) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const text = e.target.result;
        const jsonArray = await csv().fromString(text);
        console.log("jsonArray", jsonArray);
        furtherProcessJSON(jsonArray);
      };

      reader.readAsText(file);
    }
  };

  const formatter: StatisticProps["formatter"] = (value: any) => (
    <CountUp end={value as number} separator="," />
  );
  return (
    <div className="CreateGSTCredentials">
      {!fileUploaded ? (
        <div className="">
          <Uploader onSelect={handleReadFileData} />

          <Divider>
            <Typography
              style={{ color: colorPicker("neutral.500") }}
              variant="caption"
            >
              OR
            </Typography>
          </Divider>

          <TextField
            label="Import from URL"
            height={38}
            labelColor={colorPicker("neutral.700")}
            placeholder="Enter URL"
          />
        </div>
      ) : (
        <div>
          <div style={{ display: "flex" }}>
            <Statistic
              title="Total Credentials"
              value={bulkdata.length}
              formatter={formatter}
            />

            {/* <Statistic
              title="Duplicate Credentials"
              value={124}
              formatter={formatter}
              style={{ marginLeft: 28 }}
            /> */}
          </div>

          <div style={{ height: 300 }}>
            <AgTableClient
              // @ts-ignore
              rowData={bulkdata}
              footer={false}
              hideToolbar
              columnDefs={[
                {
                  headerName: "GSTIN",
                  field: "gstin",
                },
                {
                  headerName: "Username",
                  field: "username",
                },
                {
                  headerName: "Password",
                  field: "password",
                },
              ]}
            />
          </div>
        </div>
      )}
      {bulkdata.length > 0 ? (
        <div
          className="FieldGroup"
          style={{ marginTop: 38, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            size="middle"
            // disabled={!email || !password}
            loading={isLoading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: "#0057ff", marginLeft: 18 }}
            size="middle"
            loading={isLoading}
            onClick={handleCreateCredential}
          >
            Create
          </Button>
        </div>
      ) : null}
      {contextHolder}
    </div>
  );
}
